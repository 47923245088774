.message {
  display: flex;
  padding: 2px 5px;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  color: #000000;
}
.message_self {
  flex-direction: row;
  color: #26243b;
}
.message:has(input[type=checkbox]:checked) {
  background-color: #767496;
}
.message:has(input[type=checkbox]:checked):hover {
  background-color: #696788;
}
.message_select-mode {
  cursor: pointer;
  user-select: none;
}
.message_select-mode:hover {
  background-color: #514f67;
}
.message_select-mode:hover .message__text {
  background-color: #767496;
}
.message_select-mode:hover .message__text_self {
  background-color: #2b934e;
}
.message__wrapper {
  max-width: 85%;
  display: flex;
  flex-direction: column;
}
.message__wrapper_self {
  align-self: flex-end;
}
.message__text {
  width: fit-content;
  padding: 5px;
  border-radius: 0 0.6em 0.6em 0.6em;
  background: #8987a9;
  word-wrap: anywhere;
}
.message__text_self {
  background-color: #50C878;
  align-self: flex-end;
  border-radius: 0.6em 0 0.6em 0.6em;
}
.message__date {
  font-weight: bold;
  text-align: right;
}
.message__date_self {
  text-align: left;
}
.message__info {
  display: flex;
  flex-direction: row-reverse;
  font-size: 0.8em;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
}
.message__info_self {
  flex-direction: row;
}

.sender {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 1em;
  margin: 5px 5px 0;
}
.sender_self {
  flex-direction: row-reverse;
}
.sender__icon {
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  background-color: #F1EDFF;
}
.sender__name {
  color: #F1EDFF;
}

.attachments-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.attachments-box__media-content {
  display: flex;
  gap: 3px;
}
.attachments-box__non-media-content {
  display: flex;
  flex-direction: column;
  gap: 3px;
  line-height: 1.2em;
}

