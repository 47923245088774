@import "../../theme"

.messenger
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 5px

  &__wrapper
    width: 90%
    height: 90%
    overflow: hidden
    display: flex
    gap: 10px

  &__menus
    position: absolute
    pointer-events: none
    width: 100%
    height: 100%

  &__chat-space
    width: 100%

  &__unselected-chat-alert
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    font-weight: bold
    background-color: $neutral_dark700
    border-radius: $border-radius
    color: $on_neutral_dark700

.left-side
  //min-width: 27%
  //max-width: 27%
  min-width: 330px
  max-width: 330px
  display: flex
  flex-direction: column
  background-color: $neutral_dark700
  color: $on_neutral_dark700
  border-radius: $border-radius
  //box-shadow: inset 0 -15px 15px -15px rgba(0, 0, 0, 0.51)

  &__user-info
    padding: 5px
    gap: 5px
    background-color: $neutral_dark700
    display: flex
    border-top-left-radius: $border-radius
    border-top-right-radius: $border-radius
    align-items: center

    div
      overflow: hidden

    a, img
      height: 40px

    img
      background-color: $neutral_light500
      border-radius: 50%

  &__not-found-chats
    +one-stroke-text
    width: 100%
    text-align: center

  &__chat-tabs
    height: 100%
    overflow-x: hidden
    overflow-y: auto
    scrollbar-width: none