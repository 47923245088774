.input-number
  display: flex
  align-items: center

  &__buttons-box
    display: flex
    flex-direction: column
    width: 15px
    padding: 5px

  &__arrow
    width: 10px
    height: 10px