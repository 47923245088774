.chat-header {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.31);
  background-color: #615f7a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1;
}
.chat-header__info {
  display: flex;
  align-items: center;
  gap: 5px;
}
.chat-header__name {
  color: #F1EDFF;
  font-size: 1.2em;
}
.chat-header__icon {
  height: 2em;
  cursor: pointer;
}
.chat-header__button-close {
  width: 25px;
  height: 25px;
}

