.dialog-window {
  width: 350px;
}
.dialog-window__title {
  font-size: 1.2em;
  font-weight: bold;
}
.dialog-window__warning-text {
  font-size: 1.1em;
}
.dialog-window__buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.dialog-window__action-buttons-box {
  display: flex;
  gap: 5px;
}

