.better-select {
  display: inline-flex;
  width: max-content;
  max-width: 300px;
  flex-direction: column;
  background-color: #514f67;
  height: 30px;
  border: 2px solid transparent;
  justify-content: center;
  border-radius: 5px;
  position: relative;
}
.better-select_focused {
  border-color: #50C878;
}
.better-select_focused .better-select__button-open {
  transform: rotate(180deg);
}
.better-select__selected-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.better-select__search-input {
  max-width: 200px;
  height: 26px;
}
.better-select__selected-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: #514f67;
  color: #c3bbda;
}
.better-select__selected-text:focus {
  outline: none;
}
.better-select__button-open {
  width: 20px;
  height: 20px;
  padding: 5px;
}
.better-select__options-box {
  z-index: 1;
  position: absolute;
  top: 32px;
  left: -2px;
  background-color: #514f67;
  color: #c3bbda;
  padding: 5px;
  border-radius: 5px;
  width: max-content;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.51);
}
.better-select__options-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-height: 200px;
  overflow-y: auto;
}

.better-option {
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}
.better-option:hover {
  background-color: #767496;
  color: #F1EDFF;
}
.better-option_selected {
  background-color: #50C878;
  color: #000000;
}
.better-option_selected:hover {
  background-color: #2b934e;
}

