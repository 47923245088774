@import "../../theme"

.login-page
  display: flex
  flex-direction: column
  align-items: center
  background-color: $neutral_dark400
  color: $on_neutral_dark400
  padding: 10px
  border-radius: $border-radius

  &__form
    display: flex
    gap: 5px
    flex-direction: column
    align-items: center
    width: 300px