@import "../../../theme"

.edit-plane
  background-color: $neutral_dark400
  display: flex
  justify-content: space-between
  padding: 3px 5px

  &__message-box
    display: flex
    flex-direction: column
    overflow-x: hidden

  &__title
    white-space: nowrap
    font-size: 0.9em
    font-weight: bold

  &__message-text
    overflow: hidden
    text-overflow: ellipsis

  &__close-button-wrapper
    padding: 5px
    display: flex
    align-items: center
    justify-content: center

  &__button-close
    width: 20px
    height: 20px