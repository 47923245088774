.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8987a9;
  color: #26243b;
  padding: 10px;
  border-radius: 5px;
}
.login-page__form {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

