.change_user_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8987a9;
  color: #26243b;
  padding: 10px;
  border-radius: 5px;
}
.change_user_data__form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

