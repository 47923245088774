@import "../../../theme"

.context-menu
  position: absolute
  z-index: 9999
  background-color: $neutral_dark600
  color: $on_neutral_dark600
  padding: 5px
  border-radius: 6px
  display: flex
  flex-direction: column
  font-weight: bold
  user-select: none
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.31)

  &__item
    cursor: pointer
    border-radius: $border-radius
    padding: 3px

    &:hover
      background-color: $neutral
      color: $on_neutral

    &_danger
      color: $negative

      &:hover
        background-color: $negative
        color: $on_negative