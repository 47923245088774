@import "../../../theme"

.filter
  padding: 5px
  background-color: $neutral_dark700
  color: $on_neutral_dark700

  &__search-box
    display: flex
    justify-content: space-around
    align-items: center
    gap: 5px

  &__area
    padding-top: 5px
    display: flex
    flex-direction: column
    gap: 5px

  &__clause
    display: flex
    align-items: center

  &__button
    width: 30px
    height: 30px

  &__better-select
    width: 100%