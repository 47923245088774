.window {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9998;
}
.window__backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.31);
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2.5px);
}
.window__content {
  height: 90%;
  background-color: #615f7a;
  border-radius: 10px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.51);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.window__header {
  height: 50px;
  background-color: #514f67;
  color: #c3bbda;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.window__button-close {
  width: 30px;
  height: 30px;
}

