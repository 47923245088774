@import "../../../theme"

.chat
  width: 100%
  height: 100%
  overflow-x: clip
  display: flex
  background-color: $neutral_dark700
  flex-direction: column
  border-radius: $border-radius

  &__messages-list
    height: 100%
    padding: 5px
    overflow-y: auto
    display: flex
    flex-direction: column-reverse

  &__messages-wrapper
    display: flex
    flex-direction: column

  &__modals
    position: absolute
    pointer-events: none
    width: 100%
    height: 100%
    left: 0
    top: 0

  &__date
    font-weight: bold
    font-size: 1.5em
    align-self: center
    margin: 5px
    padding: 0 5px
    background-color: $neutral_dark800
    color: $on_neutral_dark800
    border-radius: 5px