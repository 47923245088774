@import "../../theme"

.admin-page
  display: flex
  width: 100%
  height: 100%
  background-color: $neutral_dark500
  color: $on_neutral_dark500

  &__left-side
    width: 350px
    background-color: $neutral_dark700
    display: flex
    flex-direction: column

  &__user-info
    background-color: $neutral_dark800
    height: fit-content
    color: $on_neutral_dark800
    padding: 5px

  &__content
    width: 100%
    overflow-y: auto
    padding: 5px

    &::-webkit-scrollbar-thumb
      background-color: $primary

.admin-menu
  padding: 5px

  &__section
    background-color: $neutral_dark400
    padding: 5px
    border-radius: $border-radius
    margin-bottom: 5px
    display: flex
    flex-direction: column

    p
      color: $on_neutral_dark400
      font-weight: bold

    &:nth-last-child(1)
      margin-bottom: 0

  &__item
    text-decoration: none
    color: $on_neutral_dark500
    margin-top: 2px
    padding: 2px 5px
    border-radius: $border-radius

    &:hover
      background-color: $neutral_dark500
      color: $on_neutral_dark500

    &_active
      background-color: $primary
      color: $on_primary

      &:hover
        background-color: $primary_hover_light
        color: $on_primary

    &_pending
      background-color: $neutral_dark500
      color: $on_neutral_dark500

.button-table-action
  width: 20px
  height: 20px
  padding-right: 5px

.form-layout
  display: grid
  align-items: center
  grid-template-columns: max-content max-content
  gap: 5px

.admin-modals
  left: 0
  top: 0
  position: absolute
  pointer-events: none
  width: 100%
  height: 100%