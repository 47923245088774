.dialog {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
}
.dialog__backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.31);
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2.5px);
}
.dialog__content {
  user-select: none;
  background-color: #696788;
  color: #F1EDFF;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.51);
}

.positive-button {
  padding: 3px;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  width: 100%;
  background-color: #50C878;
  color: #ffffff;
}
.positive-button:hover {
  background-color: #31f172;
}

.negative-button {
  padding: 3px;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  width: 100%;
  background-color: #ff5959;
  color: #ffffff;
}
.negative-button:hover {
  background-color: #fd3535;
}

.neutral-button {
  padding: 3px;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  width: 100%;
  background-color: #c2bada;
  color: #000000;
}
.neutral-button:hover {
  background-color: #a89bd2;
}

