.messenger {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.messenger__wrapper {
  width: 90%;
  height: 90%;
  overflow: hidden;
  display: flex;
  gap: 10px;
}
.messenger__menus {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.messenger__chat-space {
  width: 100%;
}
.messenger__unselected-chat-alert {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #615f7a;
  border-radius: 5px;
  color: #F1EDFF;
}

.left-side {
  min-width: 330px;
  max-width: 330px;
  display: flex;
  flex-direction: column;
  background-color: #615f7a;
  color: #F1EDFF;
  border-radius: 5px;
}
.left-side__user-info {
  padding: 5px;
  gap: 5px;
  background-color: #615f7a;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
}
.left-side__user-info div {
  overflow: hidden;
}
.left-side__user-info a, .left-side__user-info img {
  height: 40px;
}
.left-side__user-info img {
  background-color: #F1EDFF;
  border-radius: 50%;
}
.left-side__not-found-chats {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}
.left-side__chat-tabs {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

