.dialog-menu {
  width: max-content;
}
.dialog-menu__title {
  font-size: 1.2em;
  font-weight: bold;
}
.dialog-menu__items-box {
  width: 100%;
  align-items: center;
}
.dialog-menu__item {
  text-align: center;
  margin: 5px 0 0;
  padding: 5px;
  font-weight: bold;
  background-color: #c2bada;
  color: #000000;
  border-radius: 10px;
  cursor: pointer;
}
.dialog-menu__item:hover {
  background-color: #a89bd2;
}
.dialog-menu__item_danger {
  background-color: #ff5959;
  color: #ffffff;
}
.dialog-menu__item_danger:hover {
  background-color: #fd3535;
}

