.custom-input {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #514f67;
  border: 2px solid transparent;
  color: #c3bbda;
}
.custom-input_hasError {
  border: 2px solid #ff5959;
}
.custom-input_hasError .icon-image .custom-input__icon {
  background-color: #ff5959;
}
.custom-input__wrapper {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.custom-input__input {
  width: inherit;
  background-color: transparent;
  border: none;
  color: #c3bbda;
}
.custom-input__input:focus {
  outline: none;
}
.custom-input__input::placeholder {
  color: #7f7f9f;
}
.custom-input__input::-moz-placeholder {
  color: #7f7f9f;
  opacity: 1;
}
.custom-input__input:-webkit-autofill, .custom-input__input:-webkit-autofill:hover, .custom-input__input:-webkit-autofill:focus {
  -webkit-text-fill-color: #c3bbda;
  -webkit-background-clip: text;
  caret-color: #c3bbda;
  font-family: "Nunito", sans-serif;
}
.custom-input__icon {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.custom-input__input_with-border:focus ~ .icon-image .custom-input__icon {
  background-color: #50C878;
}
.custom-input:has(.custom-input__input_with-border:focus) {
  border: 2px solid #50C878;
}
.custom-input__button-erase {
  width: 25px;
  height: 25px;
}
.custom-input__alert {
  color: #ff5959;
  padding: 0 5px 2px;
}

