@import "../../../theme"

.select-plane
  background-color: $neutral_dark700
  color: $on_neutral_dark700
  padding: 5px
  z-index: 1
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.31)
  display: flex
  justify-content: space-between
  border-top: 2px solid $neutral_dark800
  align-items: center

  &__actions
    display: flex
    align-items: center

  &__action-button
    width: 22px
    height: 22px
    padding: 0 5px

    &:nth-last-child(1)
      padding-right: 0

  &__button-delete
    button
      background-color: $negative

      &:hover
        background-color: $negative_hover