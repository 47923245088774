@import "../../../theme"

$message_offset: 5px

.message
  display: flex
  padding: 2px $message_offset
  flex-direction: row-reverse
  justify-content: flex-end
  align-items: center
  gap: 5px
  color: $on_primary

  &_self
    flex-direction: row
    color: $on_neutral_dark400

  &:has(input[type=checkbox]:checked)
    background-color: $neutral_dark500

    &:hover
      background-color: $neutral_dark600

  &_select-mode
    cursor: pointer
    user-select: none

    &:hover
      background-color: $neutral_dark800

      .message__text
        background-color: $neutral_dark500

        &_self
          background-color: $primary_hover_dark

  &__wrapper
    max-width: 85%
    display: flex
    flex-direction: column

    &_self
      align-self: flex-end

  &__text
    width: fit-content
    padding: 5px
    border-radius: 0 0.6em 0.6em 0.6em
    background: $neutral_dark400
    word-wrap: anywhere

    &_self
      background-color: $primary
      align-self: flex-end
      border-radius: 0.6em 0 0.6em 0.6em

  &__date
    font-weight: bold
    text-align: right

    &_self
      text-align: left

  &__info
    display: flex
    flex-direction: row-reverse
    font-size: 0.8em
    align-items: center
    justify-content: space-between
    gap: 3px

    &_self
      flex-direction: row

.sender
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 5px
  font-weight: bold
  font-size: 1em
  margin: $message_offset $message_offset 0

  &_self
    flex-direction: row-reverse

  &__icon
    width: 1.2em
    height: 1.2em
    border-radius: 50%
    background-color: $neutral_light500

  &__name
    color: $on_neutral_dark700

.attachments-box
  display: flex
  flex-direction: column
  gap: 5px

  &__media-content
    display: flex
    gap: 3px

  &__non-media-content
    display: flex
    flex-direction: column
    gap: 3px
    line-height: 1.2em