.context-menu {
  position: absolute;
  z-index: 9999;
  background-color: #696788;
  color: #F1EDFF;
  padding: 5px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  user-select: none;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.31);
}
.context-menu__item {
  cursor: pointer;
  border-radius: 5px;
  padding: 3px;
}
.context-menu__item:hover {
  background-color: #c2bada;
  color: #000000;
}
.context-menu__item_danger {
  color: #ff5959;
}
.context-menu__item_danger:hover {
  background-color: #ff5959;
  color: #ffffff;
}

