@import "../../../../theme"

.window
  width: 100%
  height: 100%
  position: absolute
  z-index: 9998

  &__backdrop
    width: 100%
    height: 100%
    background-color: $backdrop
    pointer-events: all
    display: flex
    align-items: center
    justify-content: center
    backdrop-filter: blur(2.5px)

  &__content
    height: 90%
    background-color: $neutral_dark700
    border-radius: 10px
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.51)
    overflow: hidden
    display: flex
    flex-direction: column

  &__header
    height: 50px
    background-color: $neutral_dark800
    color: $on_neutral_dark800
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 10px

  &__button-close
    width: 30px
    height: 30px