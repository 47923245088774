.media-window
  width: 100%
  height: 100%
  position: absolute
  z-index: 200
  left: 0
  top: 0

  &__backdrop
    width: inherit
    height: inherit
    background-color: rgba(0, 0, 0, 0.72)
    pointer-events: all
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center

  &__content
    height: 80%