.upload-files-plane {
  background-color: #514f67;
  display: flex;
  border-radius: 5px;
  margin: 0 5px;
}
.upload-files-plane__wrapper {
  margin: 0 5px;
  padding: 5px 0;
  gap: 5px;
  display: flex;
  flex-flow: wrap;
  width: 100%;
}

.selected-file {
  max-height: 50px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #696788;
  border-radius: 5px;
}
.selected-file__video {
  height: inherit;
  border-radius: 5px;
}
.selected-file__image {
  display: flex;
  justify-content: center;
  height: inherit;
  width: 50px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}
.selected-file__other {
  display: flex;
  gap: 5px;
  margin: 5px;
  max-width: 200px;
  justify-content: center;
}
.selected-file__other-icon {
  width: 40px;
  height: 40px;
  mask-image: url("../../../images/file.png");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #26243b;
  border-radius: 5px;
}
.selected-file__info {
  display: flex;
  flex-direction: column;
  line-height: 1.1em;
  justify-content: center;
  overflow-x: hidden;
}
.selected-file__info p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.selected-file__actions-box {
  position: absolute;
  opacity: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.selected-file__actions-box:hover {
  opacity: 1;
}
.selected-file__action-button {
  width: 20px;
  height: 20px;
}

