.chat {
  width: 100%;
  height: 100%;
  overflow-x: clip;
  display: flex;
  background-color: #615f7a;
  flex-direction: column;
  border-radius: 5px;
}
.chat__messages-list {
  height: 100%;
  padding: 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
.chat__messages-wrapper {
  display: flex;
  flex-direction: column;
}
.chat__modals {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.chat__date {
  font-weight: bold;
  font-size: 1.5em;
  align-self: center;
  margin: 5px;
  padding: 0 5px;
  background-color: #514f67;
  color: #c3bbda;
  border-radius: 5px;
}

