@import "../../../theme"

.dialog-menu
  width: max-content

  &__title
    font-size: 1.2em
    font-weight: bold

  &__items-box
    width: 100%
    align-items: center

  &__item
    text-align: center
    margin: 5px 0 0
    padding: 5px
    font-weight: bold
    background-color: $neutral
    color: $on_neutral
    border-radius: 10px
    cursor: pointer

    &:hover
      background-color: $neutral_hover

    &_danger
      background-color: $negative
      color: $on_negative

      &:hover
        background-color: $negative_hover