.user-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 5px;
}

.user-icon {
  width: 150px;
  height: 150px;
}

