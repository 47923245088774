.filter {
  padding: 5px;
  background-color: #615f7a;
  color: #F1EDFF;
}
.filter__search-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}
.filter__area {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.filter__clause {
  display: flex;
  align-items: center;
}
.filter__button {
  width: 30px;
  height: 30px;
}
.filter__better-select {
  width: 100%;
}

