@import "../../../theme"

$size: 40px

.controls
  width: $size
  height: max-content
  display: flex
  background-color: $neutral_dark700
  padding: 5px
  border-radius: $border-radius

  &__button
    width: $size
    height: $size