@import "../../../theme"

$line_height: 1.4em

.chat-input
  display: flex
  flex-direction: column
  border-radius: $border-radius
  margin: $offset

  &__wrapper
    display: flex
    align-items: flex-end
    gap: $offset

  &__input-wrapper
    width: 100%
    display: flex
    gap: $offset
    flex-direction: column-reverse
    overflow-x: hidden

  &__input-field
    display: flex
    background-color: $neutral_dark800
    border-radius: $border-radius
    align-items: start
    justify-content: center
    padding: $offset

    textarea
      width: 100%
      font-size: 1em
      line-height: $line_height
      border: none
      resize: none
      background: transparent
      color: $on_neutral_dark800

      &:focus
        outline: none

  &__buttons-box
    display: flex
    align-items: center
    gap: $offset

  &__button
    width: calc($offset * 2 + $line_height)
    height: calc($offset * 2 + $line_height)
    cursor: pointer

    button
      background-color: $primary

    &:hover
      button
        background-color: $primary_hover_light

.file-button
  input[type=file]
    width: 0

  label
    width: 100%
    height: 100%
    display: block
    cursor: pointer

.send-button
  input[type=submit]
    display: none