@import "../../../theme"

.dialog-alert
  max-width: 250px
  background-color: $neutral_dark700

  &__top
    display: flex
    align-items: center
    gap: 5px
    font-size: 1.2em
    font-weight: bold

  &__icon
    width: 1.1em
    height: 1.1em
    background-color: $negative !important