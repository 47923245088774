@import "../../../theme"

$height: 30px
$border-width: 2px

.better-select
  display: inline-flex
  width: max-content
  max-width: 300px
  flex-direction: column
  background-color: $neutral_dark800
  height: $height
  border: $border-width solid transparent
  justify-content: center
  border-radius: $border-radius
  position: relative

  &_focused
    border-color: $primary

    .better-select__button-open
      transform: rotate(180deg)

  &__selected-box
    display: flex
    align-items: center
    cursor: pointer

  &__search-input
    max-width: 200px
    height: $height - $border-width * 2

  &__selected-text
    +one-stroke-text
    width: 100%
    border: none
    padding: 5px
    cursor: pointer
    background-color: $neutral_dark800
    color: $on_neutral_dark800

    &:focus
      outline: none

  &__button-open
    width: 20px
    height: 20px
    padding: 5px

  &__options-box
    z-index: 1
    position: absolute
    top: $height + $border-width
    left: -$border-width
    background-color: $neutral_dark800
    color: $on_neutral_dark800
    padding: 5px
    border-radius: $border-radius
    width: max-content
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.51)

  &__options-wrapper
    display: flex
    flex-direction: column
    gap: 3px
    max-height: 200px
    overflow-y: auto

.better-option
  padding: 2px
  border-radius: $border-radius
  cursor: pointer
  user-select: none

  &:hover
    background-color: $neutral_dark500
    color: $on_neutral_dark500

  &_selected
    background-color: $primary
    color: $on_primary

    &:hover
      background-color: $primary_hover_dark