@import "../../../theme"

.chat-header
  padding: 5px
  display: flex
  align-items: center
  justify-content: space-between
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.31)
  background-color: $neutral_dark700
  border-top-left-radius: $border-radius
  border-top-right-radius: $border-radius
  z-index: 1

  &__info
    display: flex
    align-items: center
    gap: 5px

  &__name
    color: $on_neutral_dark700
    font-size: 1.2em

  &__icon
    height: 2em
    cursor: pointer

  &__button-close
    width: 25px
    height: 25px