@import "../../../theme"

.dialog-window
  width: 350px

  &__title
    font-size: 1.2em
    font-weight: bold

  &__warning-text
    font-size: 1.1em

  &__buttons
    display: flex
    flex-direction: column
    gap: 5px

  &__action-buttons-box
    display: flex
    gap: 5px
