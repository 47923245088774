.chat-input {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 5px;
}
.chat-input__wrapper {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}
.chat-input__input-wrapper {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column-reverse;
  overflow-x: hidden;
}
.chat-input__input-field {
  display: flex;
  background-color: #514f67;
  border-radius: 5px;
  align-items: start;
  justify-content: center;
  padding: 5px;
}
.chat-input__input-field textarea {
  width: 100%;
  font-size: 1em;
  line-height: 1.4em;
  border: none;
  resize: none;
  background: transparent;
  color: #c3bbda;
}
.chat-input__input-field textarea:focus {
  outline: none;
}
.chat-input__buttons-box {
  display: flex;
  align-items: center;
  gap: 5px;
}
.chat-input__button {
  width: calc(10px + 1.4em);
  height: calc(10px + 1.4em);
  cursor: pointer;
}
.chat-input__button button {
  background-color: #50C878;
}
.chat-input__button:hover button {
  background-color: #3bf379;
}

.file-button input[type=file] {
  width: 0;
}
.file-button label {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}

.send-button input[type=submit] {
  display: none;
}

