.edit-plane {
  background-color: #8987a9;
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;
}
.edit-plane__message-box {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.edit-plane__title {
  white-space: nowrap;
  font-size: 0.9em;
  font-weight: bold;
}
.edit-plane__message-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.edit-plane__close-button-wrapper {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-plane__button-close {
  width: 20px;
  height: 20px;
}

