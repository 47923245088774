@import "../ChatTab/ChatTab"

$tab_height: 50px
$tab_offset: $tab_offset
$icon_size: $tab_height - $tab_offset * 2
$checkbox-width: $tab_offset * 2.5

.chat-tab-checkbox
  @extend %chat-tab

  &__label
    @extend %content
    gap: 5px
    height: $tab_height
    padding-left: $tab_offset

  &__icon
    @extend %icon
    width: $icon_size
    height: $icon_size

  &__desc
    @extend %desc

  &__title
    @extend %title

  &__group_name
    @extend %group-name

  &__checkbox
    margin: $checkbox-width / 2