$primary: #50C878
$primary_hover_light: #3bf379
$primary_hover_dark: #2b934e
$primary_disabled: #629d76
$neutral_dark400: #8987a9
$neutral_dark500: #767496
$neutral_dark600: #696788
$neutral_dark700: #615f7a
$neutral_dark800: #514f67
$neutral_dark900: #26243b
$neutral_light500: #F1EDFF

$on_primary: #000000
$on_primary_dark: $neutral_light500
$on_neutral_dark400: $neutral_dark900
$on_neutral_dark500: $neutral_light500
$on_neutral_dark600: $neutral_light500
$on_neutral_dark700: $neutral_light500
$on_neutral_dark800: #c3bbda

$status_online: #0cff0c
$status_offline: #989898

$backdrop: rgba(0, 0, 0, 0.31)

$positive: #50C878
$negative: #ff5959
$neutral: #c2bada
$positive_hover: #31f172
$negative_hover: #fd3535
$neutral_hover: #a89bd2
$on_positive: #ffffff
$on_negative: #ffffff
$on_neutral: #000000

$border-radius: 5px
$offset: 5px

=one-stroke-text
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

=mask-image($url)
  mask-image: url($url)
  mask-size: contain
  mask-repeat: no-repeat