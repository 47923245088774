@import "../../../theme"

.custom-input
  width: 100%
  max-width: 300px
  display: flex
  flex-direction: column
  border-radius: $border-radius
  background-color: $neutral_dark800
  border: 2px solid transparent
  color: $on_neutral_dark800

  &_hasError
    border: 2px solid $negative

    .icon-image .custom-input__icon
      background-color: $negative

  &__wrapper
    height: 30px
    width: 100%
    display: flex
    flex-direction: row-reverse
    align-items: center

  &__input
    width: inherit
    background-color: transparent
    border: none
    color: $on_neutral_dark800

    &:focus
      outline: none

    &::placeholder
      color: #7f7f9f

    &::-moz-placeholder
      color: #7f7f9f
      opacity: 1

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus
      -webkit-text-fill-color: $on_neutral_dark800
      -webkit-background-clip: text
      caret-color: $on_neutral_dark800
      font-family: "Nunito", sans-serif

  &__icon
    width: 20px
    height: 20px
    margin: 0 5px

  &__input_with-border:focus ~ .icon-image &__icon
    background-color: $primary

  &:has(&__input_with-border:focus)
    border: 2px solid $primary

  &__button-erase
    width: 25px
    height: 25px

  &__alert
    color: $negative
    padding: 0 5px 2px