@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap')
@import "theme"

$scrollbar-track-color: $neutral_dark800
$scrollbar-thumb-color: $neutral_dark400

*
  padding: 0
  margin: 0
  font-family: 'Nunito', sans-serif

  &::-webkit-scrollbar
    width: 5px
    height: 5px

  &::-webkit-scrollbar-thumb
    background: $scrollbar-thumb-color
    border-radius: 5px

  &::-webkit-scrollbar-track
    background: $scrollbar-track-color

@-moz-document url-prefix()
  *
    scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color
    scrollbar-width: thin

html
  box-sizing: border-box

html, body
  height: 100%

body
  overflow: hidden
  background-color: $neutral_dark500

input
  font-size: 1em

#root
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center