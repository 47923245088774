@import "../../theme"

.change_user_data
  display: flex
  flex-direction: column
  align-items: center
  background-color: $neutral_dark400
  color: $on_neutral_dark400
  padding: 10px
  border-radius: $border-radius

  &__form
    display: flex
    flex-direction: column
    gap: 5px
    align-items: center